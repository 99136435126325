<template>
    <div>
      <PageLoader v-bind:storage="appLoading" />
      <v-layout pt-4 wrap justify-left>
        <v-flex xs12 xl11 px-2>
          <v-layout pt-3 wrap justify-start>
            <v-flex xs5 sm3 md3 lg2 xl2>
              <v-layout wrap justify-center>
                <v-flex xs3 sm2 text-left align-self-center>
                  <v-img
                    src="./../../../assets/greenlogo.png"
                    contain
                    height="15px"
                    alt="STYLOOP"
                  ></v-img>
                </v-flex>
                <v-flex xs9 sm8 text-left pl-2 align-self-center>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 18px;
                      color: #1c1c1c;
                    "
                  >
                    Trial Room
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start v-if="products.length > 0">
            <template v-for="(item, i) in products">
              <v-flex xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
                <TrialRoomItem v-bind:storage="item" @stepper="winStepper" />
              </v-flex>
              <v-flex xs12 xl11 :key="i" py-4>
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-layout>
          <v-layout wrap justify-center v-if="pages > 1">
            <v-flex xs12>
              <v-pagination
                v-model="currentPage"
                :length="pages"
                :total-visible="7"
                color="#FF0000"
              ></v-pagination>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center v-if="products.length < 1">
            <v-flex xs12>
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 25px;
                  color: #000000;
                "
              >
                Oops! No Products Found
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import axios from "axios";
  import TrialRoomItem from "./trialRoomItem";
  export default {
    components: {
      TrialRoomItem,
    },
    data() {
      return {
        appLoading: false,
        ServerError: false,
        currentPage: 1,
        pages: 1,
        count: 10,
        products: [],
        totalCount: 0,
        productData: {},
        listStatus: "All",
        searchKey: null,
      };
    },
    watch: {
      listStatus() {
        this.getData();
      },
      currentPage() {
        this.getData();
      },
    },
    beforeMount() {
      this.getData();
    },
    methods: {
      winStepper(window_data) {
        if (window_data.getData) {
          this.getData();
        }
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/trial/seller/list",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            count: this.count,
            page: this.currentPage,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.products = response.data.data;
              this.pages = response.data.pages;
              this.totalCount = response.data.totalcount;
            } else {
              this.products = [];
            }
          })
          .catch((err) => {
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>