<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap py-2>
      <v-flex xs12 sm12 md12 lg6>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
            <v-img
              :src="baseURL + '/u/' + storage.product.photos[0]"
              aspect-ratio="1"
              contain
              height="100px"
              :alt="storage.product.productname"
            ></v-img>
          </v-flex>
          <v-flex xs12 sm9 md9 lg9 xl9>
            <v-layout wrap justify-center fill-height>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex lg12 text-left>
                    <router-link :to="'/Product/' + storage.product._id">
                      <span
                        style="
                          font-family: opensanslight;
                          font-size: 16px;
                          color: #000000;
                        "
                      >
                        {{ storage.product.productname }}
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 align-self-end>
                <v-layout wrap justify-start>
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Size
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ storage.size }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left xs12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                        >
                          Price
                        </span>
                      </v-flex>
                      <v-flex text-left xs12>
                        <span
                          style="font-family: opensansbold; font-size: 20px"
                        >
                          {{ storage.product.productcost.toFixed(2) }}
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 sm3 md3 lg3 xl3>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            color: #8d8d8d;
                            font-family: opensanslight;
                            font-size: 14px;
                          "
                          >Total price</span
                        >
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex text-left lg12>
                        <span
                          style="
                            font-family: opensansbold;
                            color: #3bc17a;
                            font-size: 20px;
                          "
                          >{{ storage.product.price }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex text-left xs1 text-center hidden-md-and-down>
            <v-divider vertical></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex text-left xs12 md12 lg6>
        <v-layout wrap justify-space-between fill-height>
          <v-flex sm12 md12 lg12 xl12>
            <v-layout wrap>
              <v-flex xs12 sm8 md8>
                <v-layout wrap>
                  <v-flex xs6 sm12>
                    <span
                      style="
                        color: #8d8d8d;
                        font-family: opensanslight;
                        font-size: 14px;
                      "
                    >
                      User Details
                    </span>
                  </v-flex>
                  <v-flex xs6 sm12>
                    <span
                      style="
                        font-family: opensanssemibold;
                        font-size: 15px;
                        color: #000000;
                      "
                    >
                      {{ storage.user.firstname }}
                      {{ storage.user.lastname }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm4
                pb-5
                text-left
                v-if="
                  storage.shippingStatus != 'Pending' &&
                  storage.shippingStatus != 'Confirmed' &&
                  storage.shippingStatus != 'Packed'
                "
              >
                <v-layout wrap justify-center fill-height>
                  <v-layout wrap justify-center fill-height>
                    <v-flex xs12 text-center>
                      <v-select
                        outlined
                        dense
                        color="#30B868"
                        placeholder="Change Status"
                        v-model="shippingStatus"
                        :items="shippingStatusArray"
                        item-text="name"
                        item-value="value"
                        item-color="#30B868"
                        hide-details
                        v-on:input="changeStatus(storage)"
                        item-disabled="disabled"
                      >
                        <template v-slot:item="{ item }">
                          <span class="text-left">
                            {{ item.name }}
                          </span>
                        </template>
                      </v-select>
                    </v-flex>
                  </v-layout>
                </v-layout>
              </v-flex>
              <v-flex
                xs12
                sm4
                pb-5
                text-left
                v-else
              >
              <v-layout wrap>
                  <v-flex xs6 sm12>
                    <span
                      style="
                        color: #8d8d8d;
                        font-family: opensanslight;
                        font-size: 14px;
                      "
                    >
                      Current Status
                    </span>
                  </v-flex>
                  <v-flex xs6 sm12>
                    <span
                      style="
                        font-family: opensanssemibold;
                        font-size: 15px;
                        color: #000000;
                      "
                    >
                      {{ storage.shippingStatus }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-layout wrap>
                <v-flex xs12 align-self-end>
                  <v-layout wrap justify-start>
                    <v-flex xs6 sm3 md3 lg3 xl3>
                      <v-layout wrap>
                        <v-flex text-left xs12>
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                          >
                            Phone
                          </span>
                        </v-flex>
                        <v-flex text-left xs12>
                          <span
                            style="
                              font-family: opensanssemibold;
                              font-size: 15px;
                            "
                          >
                            {{ storage.user.phone }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm4 md4 lg4 xl4>
                      <v-layout wrap>
                        <v-flex text-left xs12>
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                          >
                            Email
                          </span>
                        </v-flex>
                        <v-flex text-left xs12>
                          <span
                            style="
                              font-family: opensanssemibold;
                              font-size: 15px;
                            "
                          >
                            {{ storage.user.email }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs6 sm5 md5 lg5 xl5>
                      <v-layout wrap>
                        <v-flex text-left lg12>
                          <span
                            style="
                              color: #8d8d8d;
                              font-family: opensanslight;
                              font-size: 14px;
                            "
                            >Address</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap>
                        <v-flex text-left lg12>
                          <span
                            style="
                              font-family: opensanssemibold;
                              font-size: 15px;
                            "
                            >{{
                              storage.user.deliveryaddress[0].streetAddress
                            }}
                            {{ storage.user.deliveryaddress[0].city }}
                            {{ storage.user.deliveryaddress[0].pincode }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["storage"],
  data() {
    return {
      appLoading: false,
      timeout: 5000,
      msg: "",
      ServerError: false,
      showSnackBar: false,
      statusLoading: false,
      deleteDialogue: false,
      shippingStatus: "",
      shippingStatusArray: [
        { name: "ReachedStore", value: "ReachedStore" },
        { name: "ReadyForTrial", value: "ReadyForTrial" },
        { name: "Return", value: "Return" },
        { name: "Purchased", value: "Purchased" },
      ],
    };
  },
  beforeMount() {
    this.shippingStatus = this.storage.shippingStatus;
  },
  methods: {
    changeStatus(item) {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/trial/seller/status/update",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: item._id,
          status: this.shippingStatus,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            // this.orders = response.data.data;
            this.storage.shippingStatus = this.shippingStatus;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
  
  <style>
div.vertical-line {
  width: 1px; /* Line width */
  background-color: #8d8d8d; /* Line color */
  height: 100%; /* Override in-line if you want specific height. */
  float: left; /* Causes the line to float to left of content. 
          You can instead use position:absolute or display:inline-block
          if this fits better with your design */
}
.statusKey.v-input--selection-controls {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>
  